'use strict';

require('../thirdParty/inputmask');

module.exports = {

    profilePhoneNumber: function () {
        // mask phone number field
        var disableMasking = $('input[type=tel]').attr('data-disableMasking');

        if(disableMasking == 'true') {
            $('input[type=tel]').inputmask("remove");
        } else {
            $('input[type=tel]').inputmask('(999)-999-9999');
        }
    },

    defaultCountrySelect: function () {
        if(window.Countries != undefined) {
            var defaultCountry = window.Countries.defaultCountry;
            var selectEle = $('.newaddress-form #country');
            $.each(selectEle.find('option'), function (index, optionElement) {
                if(optionElement.id == defaultCountry) {
                    $(optionElement).prop('selected', true);
                    $(optionElement).trigger('change');
                    return;
                }
            });
        }
    }

};
